
export const BADGE={
    WORKER1 : '성실한 일꾼',
    WORKER2 : '열일하는 홍여사',
    WORKER3 : '따뜻한 마음씨',
    WORKER4 : '거래의 신',
    WORKER5 : '사랑받는 사람',
    WORKER6 : '착한 귀족 ',
    WORKER7 : '부유한 귀족',
    WORKER8 : '동네 토박이',
    WORKER9 : '지혜로운 사람',
    WORKER10 : '첫후기의 설레임',
    WORKER11 : '신뢰의 첫걸음',
    WORKER12 : '믿음직한 사람',
    WORKER13 : '항상 꾸준한 사람',
    WORKER14 : '댓글의 파워',
    WORKER15 : '따뜻함의 시작',
    WORKER16 : '거래 박사',
    WORKER17 : '행운의 신',
    WORKER18 : '꾸준함의 상징',
    WORKER19 : '동네 한바퀴',
    WORKER20 : '이동네 후기왕',
    WORKER21 : '믿고맡기는 홍여사',
}