import { useEffect, useRef, useState } from "react";
import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import styled from "styled-components";
import "../../screen/css/common.css";
import { useSpring, animated } from "@react-spring/web";
const generateRandomString = () => window.btoa(Math.random()).slice(0, 20);
const clientKey = "test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm";



const style = {
  position: 'absolute',
  top: '55%',
  left: '50%',
  height:'650px',
  overflowY:"auto",
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '14px 34px',
  zIndex:100,

};

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};



export default function MobilePayPopup({callback}) {

  const [refresh, setRefresh] = React.useState(1);
  const [open, setOpen] = React.useState(true);
  const [ready, setReady] = React.useState(false);
  const [widgets, setWidgets] = React.useState(null);
  const [amount, setAmount] = React.useState({
    currency: "KRW",
    value: 50_000,
  });


  const handleClose = () =>{
    
    setOpen(false);
    setRefresh((refresh) => refresh +1);
    callback([]);

  } 

  React.useEffect(()=>{
    setOpen(open);
  },[refresh])

  React.useEffect(() => {
    async function fetchPaymentWidgets() {
      const tossPayments = await loadTossPayments(clientKey);
      const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
      setWidgets(widgets);
    }

    fetchPaymentWidgets();
  }, [clientKey]);

  React.useEffect(() => {
    async function renderPaymentWidgets() {
      if (widgets == null) {
        return;
      }
      /**
       * 위젯의 결제금액을 결제하려는 금액으로 초기화하세요.
       * renderPaymentMethods, renderAgreement, requestPayment 보다 반드시 선행되어야 합니다.
       * @docs https://docs.tosspayments.com/sdk/v2/js#widgetssetamount
       */
      await widgets.setAmount(amount);

      await Promise.all([
        /**
         * 결제창을 렌더링합니다.
         * @docs https://docs.tosspayments.com/sdk/v2/js#widgetsrenderpaymentmethods
         */
        widgets.renderPaymentMethods({
          selector: "#payment-method",
          // 렌더링하고 싶은 결제 UI의 variantKey
          // 결제 수단 및 스타일이 다른 멀티 UI를 직접 만들고 싶다면 계약이 필요해요.
          // @docs https://docs.tosspayments.com/guides/v2/payment-widget/admin#새로운-결제-ui-추가하기
          variantKey: "DEFAULT",
        }),
        /**
         * 약관을 렌더링합니다.
         * @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
         */
        widgets.renderAgreement({
          selector: "#agreement",
          variantKey: "AGREEMENT",
        }),
      ]);

      setReady(true);
    }

    renderPaymentWidgets();
  }, [widgets]);

  return (
    <div>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
              
          <div className="wrapper w-100">
            <div className="max-w-540 w-100">
              <div id="payment-method" className="w-100" />
              <div id="agreement" className="w-100" />
              <div className="btn-wrapper w-100">
                <button
                  className="btn primary w-100"
                  onClick={async () => {
                    try {
                      /**
                       * 결제 요청
                       * 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
                       * 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
                       * @docs https://docs.tosspayments.com/sdk/v2/js#widgetsrequestpayment
                       */
                      await widgets?.requestPayment({
                        orderId: generateRandomString(),
                        orderName: "토스 티셔츠 외 2건",
                        customerName: "김토스",
                        customerEmail: "customer123@gmail.com",
                        successUrl: window.location.origin + "/sandbox/success" + window.location.search,
                        failUrl: window.location.origin + "/sandbox/fail" + window.location.search
                      });
                    } catch (error) {
                      // TODO: 에러 처리
                    }
                  }}
                >
                  결제하기
                </button>
              </div>             
            </div>     
         </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}