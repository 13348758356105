
export const LINKTYPE = {
	START : 0,
	DEVICEINFO : 1,
	CURRENTPOS :2,
    TELEPHONE:3,
}

export const MOVE = {
	REGISTER : 0,
	MAIN : 1,
}