

    const prefix = ['','잘읽은','밝은','커다란','작은','움직인','돌아온','사랑한','귀여운','용감한','빛나는',
    '대찬', '검은', '씩씩한', '조용한','신속한', '광대한','재치있는','우아한','새하얀','튼튼한',
    '꼼꼼한', '착실한', '어여쁜', '빠른','느린', '높은','깊은','적당한','슬픈','가난한','좋은','새로운','첫번째','마지막','기다란','대단한','위대한',
    '오래된','나이먹은','나쁜','이쁜','파랑','노란','놀란','아주큰',
    '큰','색다른','기쁜','행복','어른','어린','젊은','중요한','사나운',
    '화려한','똑똑한','재밌는','게으른','외로운','유명한','단정한',
    '비만인','평화적인','완벽한','공손한','재빠른','희귀한','타당한',
    '부유한','풍부한','동그란','무례한','안전한','짭짤한','겁먹은',
    '비밀의','규칙적인','겁먹은','이기적인','상급의','분리된','독린된',
    '심각한','유사한','간단한','졸리운','날씬한','매끈한','친절한',
    '사교적인','빠른','위험한','어두운','밝은','깨끗한','더러운','비싼',
    '마른','뜨거운','두꺼운','못생긴','피곤한','유명한','엄격한','고집센',
    '공격적인','검정적인','까다로운','잘속는','거만한','예민한','둔감한',
    '이상한','얼빠진','산만한','신중한','강력한','차분한','겸손한','성실한',
    '친절한','진지한','꼼꼼한','대담한','느긋한','상냥한','쾌활한','온화한',
    '완고한','매력있는','단호한','솔직한','용기있는','검성적인','비판적인',];

    const suffix = ['','오리','개','새','독수리','가위','거북이','까치','종달새','참새','부엉이',
    '기러기','올빼미','참새','까마귀','거위','강아지','고양이','거미','잠자리','메뚜기',
    '표범', '송골매','기린','너구리','늑대','노루','사슴','재규어','오소리','송아지','청설모',
    '악어','여우','하마','까치','토끼','얼룩말','말','돼지','사자','호랑이',
    '코뿔소','타조','돌고래','팬더','생쥐','젖소','문어','염소','코알라',
    '원숭이','병아리','닭','뱀','양','도마뱀','아나콘다','두더쥐','학','삵',
    '기린','코끼리','낙타','햄스터','학생','인간','수달','고라니','사슴',
    '곰','고래','북극곰','폐귄','두더지','코뿔소','소','레드판다','캥거루',
    '박쥐','제비','닭','공작새','앵무새','비둘기','딱다구리','도룡뇽','산토끼',
    '멧돼지','금붕어','퓨마','승냥이','들개','치타','하이에나','족제비','코요테',
    '독사','물고기','복숭아','바나나','살구','사과','산딸기','석류','수박',
    '오렌지','자두','자몽','귤','단감','두리안','대추','딸기','레몬','망고',
    '무화과','매실','멜론','참외','체리','키위','포도','파파야','토마토','튤립',
    '장미','쟈스민','코스모스','모과','데이지','난초','동백',];

export const SUPERADMIN_USERS_ID="IPxcQht8oijTN3sUBjhR";

function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

export const _handleCreateName = (name) =>{
   return (name + suffix[randomNumberInRange(1,100)] + randomNumberInRange(1,1000) );
}
