export const LoadingSearchAnimationStyle={
    zIndex: 11,
    position: "absolute",
    top: "45%",
    left: "42%"
}

export const LoadingMainAnimationStyle ={
    zIndex: 11,
    position: "absolute",
    top: "35%",
    left: "35%"
}


export const LoadingChatAnimationStyle ={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    top: "300px",
    position:"absolute"
}

export const LoadingChat2AnimationStyle ={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    top: "500px",
    position:"absolute"
}


export const LoadingCommunityStyle={
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    top: "300px",
    position:"absolute"
  }