export const PRIVACYLAW =
[{
    INDEX :'01.',
	LABEL : "제1조 (목적)",
	CONTENT :[
        "개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함합니다.)를 말합니다.",
        "㈜씨크로드(이하 “회사”라 한다)는 귀하의 개인정보보호를 매우 중요시하며, 『개인정보보호법』상의 개인정보보호규정을 준수하고 있습니다. “회사”는 개인정보처리방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.",
        "개인정보처리방침은 정부의 법령이나 지침의 변경, 또는 보다 나은 서비스의 제공을 위하여 그 내용이 변경될 수 있습니다. 이 경우 “회사”는 웹 사이트에서 공지사항에 이를 올리거나 이메일을 통해서 공지하고 있습니다",
        "회사”는 개인정보처리방침을 사이트 첫 화면 또는 첫 화면과의 연결화면을 통해 공개함으로써 이용자가 언제나 용이하게 보실 수 있도록 조치하고 있습니다.",
        "“회사”는 개인정보처리방침의 지속적인 개선을 위하여 개인정보처리방침을 개정하는데 필요한 절차를 정하고 있습니다."
    ],
    OPEN : false
},
{
    INDEX :'02.',
	LABEL : "제2조 (개인정보 수집에 대한 동의 및 수집 방법)",
	CONTENT :[
        '회사는 이용자께서 회사의 동의문 또는 이용약관의 내용에 대해 동의함 또는 동의하지 않음을 클릭할 수 있는 절차를 마련하여 동의함을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다. 단, 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 동의 없이 이용자의 개인정보를 수집∙이용할 수 있습니다' + 
        '\n'+  ' 1). 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적 기술적인 사유 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우 ' +
        '\n'+  ' 2). 정보통신서비스 제공에 따른 요금정산을 위하여 필요한 경우' +
        '\n'+  ' 3). 그 밖에 법률에 특별한 규정이 있는 경우  ' +  
        '\n'+  ' 4). 계약을 이행하기 위하여 필요한 개인정보로서 경제적ㆍ기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우'+  
        '\n'+  ' 5). 수집 목적과 합리적인 관련성이 있고 정보주체에게 불이익이 발생하지 않으며 안전성 확보 조치를 적용한 경우' +    
        '\n'+ ' a) 당초 수집 목적과 관련성'+  
        '\n'+  ' b) 수집 정황 또는 처리 관행에 비춰봤을때 개인정보의 추가적인 이용에 대한 예측 가능성 ' +  
        '\n'+ ' c) 정보주의 이익 침해 여부'  +   
        '\n'+  ' d) 가명처리 또는 암호화 등 안전성 확보에 필요한 조치 적용 등의 사항을 모두 고려하여 판단',
        
        '회사가 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하고 있으며, 아래와 같은 방법을 통해 개인정보를 수집합니다' +
        '\n'+ ' 1). 홈페이지 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우'   +
        '\n'+ ' 2). 제휴 서비스 또는 단체 등으로부터 개인정보를 제공받는 경우' +
        '\n'+ ' 3). 고객센터를 통한 상담 과정에서 웹페이지, 앱, 메일, 팩스, 전화 등을 통하는 경우  ' +
        '\n'+ ' 4). 온∙오프라인에서 진행되는 이벤트∙행사 등을 통한 경우',

    ],
    OPEN : false
},
{
    INDEX :'03.',
	LABEL : "제3조 (개인정보 수집∙이용 목적 및 수집항목 등)",
	CONTENT :[
        "이용자는 별도의 회원가입 절차 없이 대부분의 컨텐츠에 자유롭게 접근할 수 있습니다. 그러나 “회사”는 맞춤형 서비스를 제공하기 위하여 회원서비스 및 제휴사를 통해 이용자의 정보를 수집하고 있습니다. “회사”의 회원제 서비스를 이용하시고자 할 경우 다음의 필수정보를 입력해주셔야 하며 선택항목을 입력하시지 않았다 하여 서비스 이용에 제한은 없습니다.",
        '수집,이용동의 목적' + 
        '\n'+  ' 1). 전자금융서비스 이용자 식별, 본인확인이 필요한 서비스 제공(ARS 인증) ' +
        '\n'+  ' 2). 불법∙부정이용방지 ' +
        '\n'+  ' 3). 상담∙불만∙민원처리 ',
        '항목' +
        '\n'+  ' 1). 마원아이디(이메일) ' +
        '\n'+  ' 2). 휴대폰 번호 ',
        '보유이용기간' +
        '\n'+  ' 회원 탈퇴시 또는 계약만료시까지'+
        '재가입 방지를 위해 탈퇴요청일 24시간 동안보유'+
        '・관계법령에 따라 보존할 필요가 있는 경우 해당 법령에서 요구하는 기한까지 보관 ',
        '서비스 이용 과정에서 아래의 정보가 자동으로 생성, 수집 될 수 있습니다.- 디바이스 ID, 광고식별자, 서비스이용기록, 접속로그, IP주소, 불량 이용기록, 브라우저 버전, 기기정보(OS정보, 버전, 모델명, 제조사 정보 등)',
        '이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 의료정보 등)는 수집하지 않습니다.',
        '이용자의 개인정보는 서비스를 이용하는 시점부터 서비스를 제공하는 기간 동안에만 제한적으로 이용하고 있습니다. 이용자가 회원탈퇴를 요청하거나 이용자의 마지막 로그인 일시를 기준으로 1년이 초과되어 이용자의 계정이 탈퇴 처리되는 경우, 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 또는 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 이용자의 개인정보는 지체 없이 파기 됩니다.'+
        '\n'+  '1). 종이에 출력된 개인 정보 : 분쇄기로 분쇄하거나 소각'+                
        '\n'+  '2). 전자적 파일 형태로 저장된 개인 정보 : 기록을 재사용할 수 없는 기술적 방법을 사용하여 삭제',
        '이용자의 동의를 받아 보유하고 있는 거래정보 등을 이용자께서 열람을 요구하시는 경우 “회사”는 지체 없이 그 내역을 확인 할 수 있도록 조치합니다.',
        '“회사”가 서비스 제공을 위해 수집한 이용자의 모든 개인정보는 수집 단계부터 안전한 암호화 통신을 이용하여, 구글 클라우드 서버의 대한민국 리전에 위치한 데이타센터로 전송되며, 개인정보처리방침의 보관기간 동안 저장합니다'
    ],
    OPEN : false
},
{
    INDEX :'04.',
	LABEL : "제4조 (개인정보 자동수집 장치의 설치∙운영 및 그 거부에 관한 사항)",
	CONTENT :[
        '쿠키(cookie)는 웹사이트가 회원의 브라우저(인터넷 익스플로러, 크롬, 파이어폭스, 기타 모바일 브라우저)로 전송하는 소량의 정보입니다. “회사”는 회원에 대한 정보를 저장하고 수시로 찾아내는 `쿠키(cookie)’를 사용합니다. 쿠키는 회원의 컴퓨터 또는 모바일 기기는 식별하지만 회원을 개인적으로 식별하지는 않습니다. 또한 회원은 쿠키에 대한 선택권이 있습니다.',
        '쿠키(cookie) 설정 방법의 예' +
        '\n'+ '1). Internet Explorer : 웹 브라우저 상단의 도구 메뉴 > 인터넷 옵션 > 개인정보 > 설정'+ 
        '\n'+ '2). Chrome : 웹 브라우저 우측의 상단 설정 메뉴 > 화면 하단의 고급 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키' +
        '\n'+ '3). Edge : 웹 브라우저 우측 상단의 점선(…) 메뉴 > 설정 > 업데이트 및 보안 > 쿠키',
        '회사의 쿠키(cookie) 운용' +
        '\n'+ '1). 쿠키(cookie)에 광고식별자, 디바이스 ID를 적재해 기기별로 차별화된 정보를 제공' +  
        '\n'+ '2). 회원과 비회원의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타겟(target) 마케팅에 활용' +
        '\n'+ '3). 클릭한 정보들에 대한 세부정보와 관심 있게 둘러본 정보들에 대한 자취를 분석하여 다음 번 접속 때 개인 맞춤 서비스를 제공' + 
        '\n'+ '4). 회원들의 습관을 분석하여 서비스 개편 등의 척도로 이용',
     
    ],
    OPEN : false
},
{
    INDEX :'05.',
	LABEL : "제5조 (개인정보의 제3자 제공에 대한 동의)",
	CONTENT :[
        '“회사”는 회원의 개인정보를 제3조(개인정보수집∙이용 목적 및 수집항목 등)에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다.',
        '전항에도 불구하고, 다음의 경우에는 예외로 합니다.' +
        '\n'+'1). 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우' +
        '\n'+'2). 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우'
        ,
        '정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우',
        '수집 목적과 합리적인 관련성이 있고 정보주체에게 불이익이 발생하지 않으며 안전성 확보 조치를 적용한 경우'+
        '\n'+'- 당초 수집 목적과 관련성'+
        '\n'+'- 수집 정황 또는 처리 관행에 비춰봤을때 개인정보의 추가적인 이용∙제공에 대한 예측 가능성'+
        '\n'+'- 정보주체의 이익 침해 여부'+
        '\n'+'- 가명처리 또는 암호화 등 안전성 확보에 필요한 조치 적용 등의 사항을 모두 고려하여 판단',
    
    ],
    OPEN : false
},
{
    INDEX :'06.',
	LABEL : "제6조 (개인정보의 열람, 정정)",
	CONTENT :[
        '이용자는 언제든지 등록되어 있는 본인의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 `내정보` > `닉네임영역`을 클릭하여 직접 열람 또는 정정하거나, 개인정보보호책임자 및 담당자에게 서면, 전화 또는 E-mail로 연락하시면 지체 없이 조치하겠습니다.',
        '이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다.',
        '잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정하도록 조치하겠습니다.',
    ],
    OPEN : false
},
{
    INDEX :'07.',
	LABEL : "제7조 (동의 철회)",
	CONTENT :[
        '회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 회원이 동의한 내용을 회원은 언제든지 철회하실 수 있습니다. 동의철회는 `내정보` > `닉네임영역` > `회원탈퇴`를 클릭하거나 개인정보보호책임자에게 팩스, 우편, 고객센터, 전화 등으로 연락하시면 개인정보의 삭제 등 필요한 조치를 하겠습니다. 동의 철회를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는 그 사실을 귀하께 지체 없이 통지하도록 하겠습니다.',
        '“회사”는 개인정보의 수집에 대한 동의철회(회원탈퇴)를 개인정보를 수집하는 방법보다 쉽게 할 수 있도록 필요한 조치를 취하겠습니다.',
    ],
    OPEN : false
},
{
    INDEX :'08.',
	LABEL : "제8조 (개인정보보호를 위한 기술 및 관리적 대책)",
	CONTENT :[
        '기술적 대책 : “회사”는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.'+
        '\n'+'1. 이용자의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.'+
        '\n'+'2. “회사”는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.'+
        '\n'+'3. “회사”는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다.'+
        '\n'+'4. 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.'
        ,
        '관리적 대책',
        '1. “회사”는 귀하의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는 자는 다음과 같습니다.'+
        '\n'+'a) 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자'+
        '\n'+'b) 고객의 불만 및 이용문의 처리 업무를 수행하는 자'+
        '\n'+'c) 개인정보보호책임자 및 담당자 등 개인정보관리업무를 수행하는 자'+
        '\n'+ 'd) 기타 업무상 개인정보의 처리가 불가피한 자'+
        '2. 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.',
        '3. 개인정보 관련 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다.',
        '4. 사내 개인정보보호 전담기구를 통해 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.',
        '5. “회사”는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 아이디와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.',
        '6. 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 “회사”는 즉각 귀하께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.',
    ],
    OPEN : false
},
{
    INDEX :'09.',
	LABEL : "제9조 (링크사이트)",
	CONTENT :[
        '“회사”는 이용자들께 다른 “회사”의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 “회사”는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.',
        ' “회사”가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 “회사”와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.',
    ],
    OPEN : false
},
{
    INDEX :'10.',
	LABEL : "제10조 (개인정보의 위탁처리)",
	CONTENT :[
        '“회사”는 서비스 향상을 위해서 이용자의 개인정보를 외부에 수집/처리/관리 등을 위탁하여 처리할 수 있습니다.',
        '개인정보의 처리를 위탁하는 경우에는 위탁기관 및 그 사실을 홈페이지를 통해 미리 이용자께 고지하겠습니다. 다만, 재화 또는 서비스를 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는 이용자에게 개별적으로 이메일 주소 등을 통해 이용자에게 개별 통지하되, “회사”가 과실 없이 서면, 전자우편 등의 방법으로 위탁하는 업무의 내용과 수탁자를 귀하에게 알릴 수 없는 경우에 해당사항을 홈페이지에 30일 이상 게시하겠습니다.',
        '개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및 사고시의 책임부담, 위탁기간, 처리 종료후의 개인정보의 반환 또는 파기 등을 명확히 규정하고 당해 계약내용을 서면 또는 전자적으로 보관하겠습니다.',
    ],
    OPEN : false
},
{
    INDEX :'11.',
	LABEL : "제11조 (이용자의 권리와 의무)",
	CONTENT :[
        '이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.',
        '이용자은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 귀하의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 등에 의해 처벌받을 수 있습니다.',
    ],
    OPEN : false
},
{
    INDEX :'12.',
	LABEL : "제12조 (의견수렴 및 불만처리)",
	CONTENT :[
        '“회사”는 이용자의 의견을 소중하게 생각하며, 이용자는 의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.',
        '“회사”는 이용자와의 원활한 의사소통을 위해 고객센터를 운영하고 있으며 연락처는 아래와 같습니다' +
        '\n'+'[고객센터]' +
        '\n'+'- 전자우편 : help@marone.co.kr' +
        '\n'+'- 전화번호 : 1800-9700',
        '기타 개인정보에 관한 상담이 필요한 경우에는 “회사”의 위 전자우편으로 문의하실 수 있으며, 국가기관에 신고나 상담이 필요한 경우에는 아래의 연락처에 문의하셔서 도움을 받으실 수 있습니다.' +
        '\n'+'1). 개인정보침해신고센터(http://privacy.kisa.or.kr / 국번 없이 118)' +
        '\n'+'2). 대검찰청 사이버수사과(http://spo.go.kr / 국번 없이 1301)' +
        '\n'+'3). 경찰청 사이버수사국(http://ecrm.cyber.go.kr / 국번 없이 182)'+
        '\n'+'4). 개인정보 분쟁조정위원회(http://www.kopico.go.kr / 1833-6972)',
    ],
    OPEN : false
},
{
    INDEX :'13.',
	LABEL : "제13조 (개인정보 보호책임자)",
	CONTENT :[
        '“회사”는 이용자께서 좋은 정보를 안전하게 이용할 수 있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어 이용자께 고지한 사항들에 반하는 사고가 발생할 시에 개인정보보호책임자가 모든 책임을 집니다. 그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 귀하의 개인정보를 처리하는 책임자 및 담당자는 다음과 같으며 개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고 있습니다.',
        '\n'+'개인정보 보호책임자' +
        '\n'+'- 성 명 : 이행렬 (기술개발총괄)' +
        '\n'+'- 전자우편 : help@marone.co.kr' 
    
    ],
    OPEN : false
},
{
    INDEX :'14.',
	LABEL : "제14조 (개인위치정보의 처리)",
	CONTENT :[
        '“회사”는 개인위치정보주체에게 아래와 같은 서비스를 제공할 목적으로 개인위치정보를 이용하고 있으며 개인위치정보 처리과정에서 위치정보를 시스템에 저장하지 않습니다.' +
        '\n'+'1). 위치정보를 활용한 검색결과 제공 서비스 : 정보 검색을 요청하거나 개인위치정보주체 또는 이동성 있는 기기의 위치정보를 제공 시 본 위치정보를 이용한 검색결과 및 주변결과(제휴 매장 등)를 제시' +
        '\n'+'2). 이용자 위치를 활용한 광고정보 제공 : 검색결과 또는 기타 서비스 이용 과정에서 개인위치정보주체 또는 이동성 있는 기기의 위치를 이용하여 광고소재를 제시',
        '“회사”는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 개인위치정보주체에 대한 위치정보 이용ㆍ제공사실 확인자료를 위치정보시스템에 자동으로 기록하며, 6개월간 보관합니다.',
        '“회사”는 개인위치정보를 저장하지 않고 있지만 향후 저장 시 개인위치정보의 처리목적을 달성해 파기가 필요해질 경우 복구 또는 재생 불가능한 아래와 같은 방법으로 파기하겠습니다.'+
        '\n'+'1). 종이에 출력된 개인 정보 : 분쇄기로 분쇄하거나 소각' +
        '\n'+'2). 전자적 파일 형태로 저장된 개인 정보 : 기록을 재사용할 수 없는 기술적 방법을 사용하여 삭제',
        '“회사”는 개인위치정보를 제3자에게 제공하지 않고 있습니다. 향후 개인위치정보주체가 지정한 제3자에게 제공이 필요한 경우에는 아래와 같은 조치를 취하겠습니다.' +
        '\n'+'1). 제공받는 자 및 제공목적을 사전에 개인위치정보주체에게 고지하고 동의 수령' + 
        '\n'+'2). 개인위치정보를 수집한 당해 통신단말장치로 매회 개인위치정보주체에게 제공받는 자, 제공일시 및 제공목적 즉시 통지' + 
        '\n'+'3). 개인위치정보주체가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 통신단말장치를 사용하거나 별도의 통신단말장치, 전자우편 또는 전자 메시지로 통보할 것을 미리 요청한 경우 미리 특정하여 지정한 통신단말장치, 전자우편 또는 전자 메시지로 통지',
 
    ],
    OPEN : false
},
{
    INDEX :'15.',
	LABEL : "제15조 (개인정보 처리방침의 개정과 그 공지)",
	CONTENT :[
        '개인정보 처리방침을 변경하는 경우 “회사”는 홈페이지 첫 화면의 공지사항란 또는 별도의 창을 통하여 지체 없이 공지하고, 이용자가 언제든지 변경된 사항을 쉽게 알아볼 수 있도록 조치하겠습니다.'+
        '\n'+'공지일자 : 2022년 11월 14일'+
        '\n'+'시행일자 : 2022년 11월 14일'
    ],
    OPEN : false
},

]

export const USELAW = [
    {
        INDEX :'01.',
        LABEL : "제1조 (목적)",
        CONTENT :[
        '이 약관은 ㈜씨크로드(이하 “회사”라 한다)가 운영하는 종합 마원 플랫폼(이하 “플랫폼”이라 한다)에서 제공하는 서비스(이하 “서비스”라 한다)를 이용함에 있어 “회사”와 “이용자”의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.' +
        '\n'+'※PC통신, 무선 등을 이용하는 경우에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'02.',
        LABEL : "제2조 (정의)",
        CONTENT :[
        '“플랫폼”이란 “이용자”가 컴퓨터 등 정보통신설비를 이용하여 “서비스”를 이용할 수 있도록 “회사”가 제공하는 가상의 영업장을 말하며, 아울러 “플랫폼”을 운영하는 사업자의 의미로도 사용합니다.',
        '“이용자”란 “플랫폼”을 통하여 이 약관에 따라 제공하는 서비스를 받는 회원 및 비회원을 말합니다.',
        '“회원”이라 함은 “플랫폼”에 회원등록을 한 자로서, 계속적으로 “플랫폼”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.',
        '“비회원”이라 함은 회원에 가입하지 않고 “플랫폼”이 제공하는 서비스를 이용하는 자를 말합니다.',
        '서비스 수수료란 “이용자”가 “서비스”를 이용할 때 “플랫폼”에 지급하는 수수료를 말합니다',
        '“게시물”이란 “이용자”가 “서비스”이용 시 게시하는 리뷰, 사진, 글, 댓글, 동영상, 프로필사진 등의 콘텐츠 일체를 의미합니다.',
        ],
        OPEN : false
    },    {
        INDEX :'03.',
        LABEL : "제3조 (약관 등의 명시와 설명 및 개정)",
        CONTENT :[
        '“회사”는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, E-mail주소, 사업자등록번호, 통신판매업 신고번호, 개인정보보호책임자등을 “이용자”가 쉽게 알 수 있도록 “플랫폼”의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 “이용자”가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.',
        '“회사”는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제에 관한 법률』, 『전자문서 및 전자거래기본법』, 『전자금융거래법』, 『전자서명법』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『소비자기본법』등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.',
        '"회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 플랫폼의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, "이용자"에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "회사"는 개정 전 내용과 개정 후 내용을 "이용자"가 알기 쉽도록 표시합니다.',
        '"회원"은 변경된 약관에 동의하지 않을 권리가 있으며, 동의하지 않을 경우에는 서비스이용을 중단하고 탈퇴할 수 있습니다.',
        '"회원"이 전④항에 따라 약관에 대한 반대의사를 표시하지 않고 "서비스"를 이용한 경우에는 약관에 동의한 것으로 봅니다.',
        '이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률 및 관계법령 또는 상관례에 따릅니다.',
        ],
        OPEN : false
    },    {
        INDEX :'04.',
        LABEL : "제4조 (회원가입)",
        CONTENT :[
        '"이용자"는 "플랫폼"이 정한 절차에 따라 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.',
        '"회사"는 제①항과 같이 회원으로 가입할 것을 신청한 "이용자" 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다' +
        '\n'+'1). 회원자격 상실 후 24시간이 경과하지 않은 경우' +       
        '\n'+'2). 등록 내용에 타인의 정보를 사용한 경우' + 
        '\n'+'3). 만 14세 미만의 아동이 신청하는 경우',
        ],
        OPEN : false
    },    {
        INDEX :'05.',
        LABEL : "제5조 (회원 탈퇴 및 자격 상실 등)",
        CONTENT :[
        '회원은 "회사"에 언제든지 탈퇴를 요청할 수 있으며 "회사"는 신속하게 회원탈퇴를 처리합니다. 이 경우 제공된 쿠폰 및 포인트는 모두 소멸합니다.',
        '회원이 다음 각 호의 사유에 해당하는 경우, "플랫폼"은 회원자격을 제한 및 정지시킬 수 있습니다'+
        '\n'+'1). 가입 신청 시에 허위 내용을 등록한 경우' +
        '\n'+'2). 다른 사람의 "플랫폼" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우' +
        '\n'+'3). "플랫폼"을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우'+
        '\n'+'4).제 22조에 따른 "이용자"의 의무를 지키지 아니하였을 경우',
        '"회사"가 회원 자격을 제한․정지 시킨 후, 같은 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 "회사"는 회원자격을 상실시킬 수 있습니다.',
        '회사는 회원이 계속해서 12개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.',
        '본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 회사의 이용제한정책에서 정하는 바에 의합니다.',
        '본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는 제6조[회원에 대한 통지]에 따라 통지합니다.',
        '회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.',
        ],
        OPEN : false
    },    {
        INDEX :'06.',
        LABEL : "제6조 (회원에 대한 통지)",
        CONTENT :[
        '"회사"가 회원에 대한 통지를 하는 경우, 회원이 "회사"에게 제공한 E-mail, SMS와 APP 메시지 등으로 할 수 있습니다.',
        '회사"는 불특정다수 회원에 대한 통지의 경우 1주일이상 "플랫폼"게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지합니다.',
        ],
        OPEN : false
    },    {
        INDEX :'07.',
        LABEL : "제7조 (서비스의 제공 및 변경)",
        CONTENT :[
        '"플랫폼"은 다음과 같은 업무를 수행합니다.'+
        '\n'+'1). 시설에 대한 정보제공' +
        '\n'+'2). 서비스이용에 대한 청약 접수' + 
        '\n'+'3). 구매계약이 체결된 서비스에 대한 통지' +
        '\n'+'4). 기타 "플랫폼"이 정하는 업무',
        '"플랫폼"이 "이용자"에게 접수한 청약에 대한 변경내용 및 제휴판매자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 "이용자"에게 통지 가능한 연락처로 신속하게 통지합니다.',
        '전항의 경우 "플랫폼"은 이로 인하여 "이용자"가 입은 손해를 배상합니다. 다만, "플랫폼"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.',
        ],
        OPEN : false
    },    {
        INDEX :'08.',
        LABEL : "제8조 (서비스의 이용시간 및 중단)",
        CONTENT :[
        '"플랫폼"의 이용은 특별한 사유가 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유 또는 정기점검 등의 필요로 "회사"가 지정한 날 등의 경우에는 "플랫폼"을 일시적으로 중단할 수 있습니다.',
        '"회사"는 "플랫폼"의 원활한 운영을 위하여 기간을 정하여 사전에 공지하고 서비스를 중지할 수 있으며, 불가피하게 긴급한 조치를 하여야 하는 경우 사후에 통지할 수 있습니다.',
        '고객센터 운영시간은 아래와 같이 규정합니다.마원 고객행복센터 운영시간 : 오전 9시부터 오후 6시까지고객행복센터 이용불가 시간에는 Kakao > 친구목록 ‘힐리 고객센터’에서 문의를 받고 있으며 순차적으로 답변이 진행됩니다.',
        ],
        OPEN : false
    },    {
        INDEX :'09.',
        LABEL : "제9조 (서비스이용 및 개인정보 제공 동의 등)",
        CONTENT :[
        '이용자"는 "플랫폼"상에서 다음 또는 이와 유사한 방법에 의하여 서비스를 이용하며, "플랫폼"은 "이용자"가 서비스를 이용을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.'+
        '\n'+'1. 시설 등의 검색 및 선택' +
        '\n'+'2. "이용자"의 성명, 전화번호, 이용프로그램타입, 이용시간 등의 입력'+
        '\n'+'3. 약관내용, 취소•환불이 제한되는 상품안내, 시설별 이용규정 및 취소환불규정의 동의 등 비용부담과 관련한 내용에 대한 확인'+
        '\n'+'4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시'+
        '\n'+'5. 결제방법의 선택',
        ],
        OPEN : false
    },    {
        INDEX :'10.',
        LABEL : "제10조 게시물(회원의 게시물 등) 정책",
        CONTENT :[
        '회원 탈퇴 이후 회원이 작성하였던 게시물 및 댓글 등은 삭제되지 않으며, 회원 탈퇴로 인하여 회원 정보가 삭제되어 작성자 본인을 확인할 수 없어 게시물 편집 및 삭제가 원천적으로 불가합니다. 회원이 작성한 게시물의 삭제를 원할 경우에는 회원 탈퇴 이전에 게시물을 모두 삭제해야 합니다.',
        '회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있으며, 회사는 회원이 게시하거나 등록하는 서비스의 내용물이 다음 각 항에 해당한다고 판단되는 경우 또는 제3자로부터의 신고가 접수된 경우 관련 절차에 따라 사전통지 없이 임시조치 및 삭제할 수 있고, 이에 대하여 회사는 어떠한 책임도 지지 않습니다.' +
        '\n'+'1. 공서양속에 위반되는 내용일 경우' +
        '\n'+'2. 동의없이 타인의 정보를 게시물에 노출한 경우' +   
        '\n'+'3. 범죄적 행위에 결부된다고 인정되는 경우' +    
        '\n'+'4. 저작권, 상표권 등 회사 또는 제3자의 권리를 침해하는 내용일 경우' +   
        '\n'+'5. 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 경우' +     
        '\n'+'6. 회사에서 규정한 게시물 원칙에 어긋나거나, 게시물을 작성하는 위치에 부여된 성격에 부합하지 않는 경우' +     
        '\n'+'7. 사업주 변경 또는 인테리어 공사 등에 따른 권리자(사업주)의 게시물 중단 또는 삭제가 있는 경우' +    
        '\n'+'8. 정당한 사유 없이 회사의 영업을 방해하는 내용을 기재하는 경우' +      
        '\n'+'9. 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를 훼손하는 글 또는 타인을 비방하는 게시물의 경우' +  
        '\n'+'10. 음란 정보, 선정적인 정보, 폭력적이거나 잔인한 정보, 저속한 언어나 욕설, 차별적 표현, 도박과 사행성 정보 기타 사회통념상 허용되지 않는 정보 등이 포함된 게시물의 경우' +
        '\n'+'11. 기타 관련법령에 위반된다고 판단되는 경우',
        '회사는 ①항에 따른 권리자의 요청이 없는 경우라도 회사 또는 제3자의 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치(블라인드) 및 삭제 등의 조치를 취할 수 있습니다.',
        '본 조 제2항 각 호의 어느 하나에 해당하는 게시물이 확인되는 경우 이용자는 언제든지 고객센터(별도로 마련된 신고절차가 있을 경우 해당 절차)를 통해 회사에 신고할 수 있습니다.',
        ],
        OPEN : false
    },    {
        INDEX :'11.',
        LABEL : "제11조 (게시물에 대한 권리)",
        CONTENT :[
        '회원이 서비스 내에 게시한 게시물(회원간 전달 포함)의 저작권은 회원에게 있습니다. 다만 회사는 서비스 내에 게시 목적으로 이를 사용할 수 있습니다.',
        '회사가 서비스 내 게재 이외의 목적으로 회원의 게시물을 사용할 경우 게시물에 대한 게시자를 반드시 명시해야 합니다. 단, 게시자를 알 수 없는 익명 게시물이나 비영리적인 경우에는 그러하지 아니합니다.',
        '회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램 저작권 등을 침해하더라도 이에 대한 민, 형사상의 책임을 부담하지 않습니다. 만일 회원이 타인의 저작권, 프로그램저작권 등을 침해하였음을 이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 회사의 면책을 위하여 노력하여야 하며, 회사가 면책되지 못한 경우 회원은 그로 인해 회사에 발생한 모든 손해를 부담하여야 합니다.',
        '회사는 회원이 해지하거나 적법한 사유로 해지된 경우 해당 회원이 게시하였던 게시물을 삭제할 수 있습니다.',
        ],
        OPEN : false
    },    {
        INDEX :'12.',
        LABEL : "제12조 (결제수단)",
        CONTENT :[
        '"플랫폼"에서 제공하는 서비스를 이용하는 것에 대한 결제방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다' +
        '\n'+'폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체' + 
        '\n'+'선불카드, 직불카드, 신용카드 등의 각종 카드 결제' +   
        '\n'+'온라인무통장입금' +  
        '\n'+'전자화폐에 의한 결제',     
        "수령 시 대금지급" + 
        '\n'+'"플랫폼"이 발행한 포인트에 의한 결제' +
        '\n'+'"플랫폼"과 계약을 맺었거나 "플랫폼"이 인정한 상품권에 의한 결제'+ 
        '\n'+'기타 전자적 지급 방법에 의한 대금 지급 등',
        ],
        OPEN : false
    },    {
        INDEX :'13.',
        LABEL : "제13조 (거래기록의 보관)",
        CONTENT :[
        '거래 기록은 전자상거래 등에서의 소비자 보호에 관한 법률 규정에 의해 일정기간 동안 보존할 수 있습니다.',
        ],
        OPEN : false
    },    {
        INDEX :'14.',
        LABEL : "제14조 (포인트, 할인쿠폰)",
        CONTENT :[
        '"회사"는 "플랫폼"을 이용하는 회원에게 일정 금액 또는 일정 비율을 할인 받을 수 있는 할인 쿠폰을 발급할 수 있습니다. 이 쿠폰은 "회사"에서 그 사용 대상, 사용 방법, 사용 기간, 할인 금액 등을 정할 수 있습니다. 할인쿠폰의 종류 또는 내용은 "회사"의 정책에 따라 달라질 수 있습니다.',
        '"회사"는 할인쿠폰의 사용 대상, 사용 방법, 사용 기간, 할인 금액 등을 할인쿠폰 또는 서비스 화면에 별도로 표시하거나 통지합니다.',
        '"회원"은 할인쿠폰을 "회원" 본인의 구매에 사용할 수 있는 권한만을 가지며, 어떠한 경우에도 이를 타인에게 실질적으로 매매 또는 양도할 수 없습니다.',
        '"포인트"란 플랫폼에서 서비스 이용 시 현금처럼 사용할 수 있는 현금 등가의 전자적 지급수단을 말합니다.',
        '포인트는 다음 각 호에서 정한 유효기간 내에서 사용할 수 있으며, 유효기간 경과 시 자동 소멸됩니다.'+
        '\n'+'1)."무상 포인트"의 유효기간은 지급일로부터 최대 1년(12개월)이며, 이벤트 목적으로 지급된 포인트의 유효기간은 지급 시 부여된 유효기간에 따라 상이합니다.'     
        ,
        '본 약관 제 5조에 따라 회원 탈퇴 및 회원 자격이 상실된 경우, 탈퇴 및 자격 상실일까지 지급된 무상 포인트는 자동으로 소멸되며, 미사용 유상 포인트는 현금으로 환급됩니다.',
        '"무상 포인트" 및 "할인쿠폰"은 현금 및 현금화되는 수단으로 환급될 수 없습니다.',
        '포인트 차감 순서는 유효기간 도래일이 빠른 순으로 차감되고, 유효기간이 동일한 경우에는 무상 포인트가 우선 차감됩니다.'
        ],
        OPEN : false
    },    {
        INDEX :'15.',
        LABEL : "제15조 (수신확인 통지, 서비스이용 변경 및 취소)",
        CONTENT :[
        '"플랫폼"은 "이용자"의 서비스이용신청이 있는 경우 "이용자"에게 수신확인통지를 합니다.',
        '수신확인통지를 받은 "이용자"는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 서비스이용에 대한 변경 및 취소를 요청할 수 있고 "플랫폼"은 "이용자"가 동의한 시설별 이용규정 및 취소환불규정 등의 구제범위 이내에서 처리하여야 합니다.',
        ],
        OPEN : false
    },    {
        INDEX :'16.',
        LABEL : "제16조 (개별 서비스 약관 및 이용조건)",
        CONTENT :[
        '"회사"는 개별 서비스와 관련한 별도의 약관 및 이용정책을 둘 수 있으며, 개별서비스에서 별도로 적용되는 약관 및 정책에 대한 동의는 "회원"이 개별서비스를 이용할 경우 별도의 동의절차를 거치게 됩니다. 이 경우 개별 서비스에 대한 약관 및 정책이 본 약관에 우선합니다.',
        '전항에도 불구하고 "회사"는 개별 서비스에 대한 이용정책에 대해서는 "서비스"를 통해 공지할 수 있으며, "이용자"는 이용정책을 숙지하고 준수하여야 합니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'17.',
        LABEL : "제17조 (환급)",
        CONTENT :[
        '"플랫폼"은 "이용자"가 신청한 제휴판매자 상품이 품절 등의 사유로 제공을 할 수 없을 때에는 지체 없이 그 사유를 "이용자"에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'18.',
        LABEL : "제18조 (취소, 변경, 환불 등)",
        CONTENT :[
        '서비스 이용에 대한 취소 및 환불규정은 전자상거래 등에서의 소비자보호에 관한 법령을 준수합니다.',
        '"제휴판매자"는 별도의 취소 및 환불규정을 제정할 수 있으며 이를 상세페이지에 기재하고 "이용자"의 동의를 받은 경우 우선 적용됩니다.',
        '"회사"는 "제휴판매자" 에게 전②항의 규정이 없는 경우를 위하여 시설 별 취소환불규정을 제정할 수 있으며 이를 상세페이지에 기재하고 "이용자"의 동의를 받아 적용합니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'19.',
        LABEL : "제19조 (개인정보보호)",
        CONTENT :[
        '"회사"는 "이용자"의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.',
        '"회사"는 회원가입 시 서비스에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 서비스이용 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.',
        '"회사"는 "이용자"의 개인정보를 수집•이용하는 때에는 당해 "이용자"에게 그 목적을 고지하고 동의를 받습니다.',
        '"회사"는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용•제공단계에서 당해 "이용자"에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.',
        '"플랫폼"이 제②항과 제③항에 의해 "이용자"의 동의를 받아야 하는 경우에는 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제1항이 규정한 사항을 미리 명시하거나 고지해야 하며 "이용자"는 언제든지 이 동의를 철회할 수 있습니다.',
        '"이용자"는 언제든지 "플랫폼"이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "플랫폼"은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. "이용자"가 오류의 정정을 요구한 경우에는 "플랫폼"은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.',
        '"플랫폼"은 개인정보 보호를 위하여 "이용자"의 개인정보를 처리하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 "이용자"의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 "이용자"의 손해에 대하여 모든 책임을 집니다.',
        '"플랫폼" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.',
        '"플랫폼"은 개인정보의 수집•이용•제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집•이용•제공에 관한 "이용자"의 동의거절 시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집•이용•제공에 관한 "이용자"의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'20.',
        LABEL : "제20조 (회사의 의무)",
        CONTENT :[
        '"회사"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는데 최선을 다하여야 합니다.',
        '"회사"는 "이용자"가 안전하게 서비스를 이용할 수 있도록 "이용자"의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.',
        '"회사"는 이외에 관계 법령이 정한 의무사항을 준수합니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'21.',
        LABEL : "제21조 (회원의 ID 및 비밀번호에 대한 의무)",
        CONTENT :[
        'ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.',
        '"회원"은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.',
        '"회원"이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "회사"에 통보하고 "회사"의 안내가 있는 경우에는 그에 따라야 합니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'22.',
        LABEL : "제22조 (“이용자”의 의무)",
        CONTENT :[
        '이용자는 다음 행위를 하여서는 안되며, 적발 시 회원탈퇴, 포인트 회수, 쿠폰 회수 등의 조치를 받을 수 있으며, 경우에 따라 경고, 일시정지, 영구이용정지 등으로 서비스 이용을 단계적으로 제한하는 조치를 받을 수 있습니다.'+
        '\n'+'신청 또는 변경시 허위 내용의 등록' +
        '\n'+'타인의 정보 도용' +
        '\n'+'"플랫폼"에 게시된 정보의 변경' +
        '\n'+'"플랫폼"이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시' +
        '\n'+'"플랫폼" 및 기타 제3자의 저작권 등 지적재산권에 대한 침해' +
        '\n'+'"플랫폼" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위' +
        '\n'+'외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 "플랫폼"에 공개 또는 게시하는 행위' +
        '\n'+'회사가 정한 규정외의 방법으로 포인트, 쿠폰, 기타"회사"가 제공하는 혜택을 적립하거나 사용하는 등의 행위' +
        '\n'+'"이용자"본인에게 제공된 포인트 또는 쿠폰을 타인에게 판매하거나 양도하는 행위' +
        '\n'+'고객행복센터 상담 내용이 욕설, 폭언, 성희롱 등에 해당하는 행위' +
        '\n'+'확인되지 않은 허위의 게시물을 작성하는 행위' +
        '\n'+'정당한 사유 없이 "회사"의 영업을 방해하는 내용을 기재하는 행위' +
        '\n'+'리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또 모방 기타 변형하는 행위' +
        '\n'+'자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 "회사"의 서버에 부하를 일으켜 "회사"의 정상적인 서비스를 방해하는 행위' +
        '\n'+'제휴판매자의 시설 이용 과정에서 욕설, 폭언, 성희롱 등에 해당하는 행위로 제휴판매자의 시설 업무환경을 심각히 어지럽히는 경우' +
        '\n'+'제휴판매자의 시설 이용 과정에서 기물 파손 등의 행위로 제휴판매자에게 재산상의 손해를 발생시키는 경우' +
        '\n'+'기타 관계법령에 위반된다고 판단되는 행위',

   
        ],
        OPEN : false
    },
    {
        INDEX :'23.',
        LABEL : "제23조 (“플랫폼”과 연결된 “사이트”와의 관계)",
        CONTENT :[
        '"플랫폼"과 연결된 "사이트"란 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우를 말합니다.',
        '"플랫폼"과 연결된 "사이트"가 독자적으로 제공하는 재화 등에 의하여 "이용자"와 행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 "플랫폼"의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.',
        '“플랫폼"에서 제공되는 서비스 외에 "플랫폼"과 단순한 링크로 연결되어 제 3 자가 제공하는 서비스에 대하여는 "회사"의 개인정보처리방침이 적용되지 않으며 "회사"는 제 3 자의 사이트에 대하여 책임지지 않습니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'24.',
        LABEL : "제24조 (저작권의 귀속 및 이용제한)",
        CONTENT :[
        '"회사"가 작성한 저작물에 대한 저작권 기타 지적재산권은 "회사"에 귀속합니다',
        '"이용자"는 "플랫폼"을 이용함으로써 얻은 정보 중 "회사"에게 지적재산권이 귀속된 정보를 "회사"의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다',
        '"회사"는 약정에 따라 "이용자"에게 귀속된 저작권을 사용하는 경우 당해 "이용자"에게 통보하여야 합니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'25.',
        LABEL : "제25조 (면책)",
        CONTENT :[
        '"회사"는 "제휴판매자"와 "이용자" 간의 상품거래를 중개하는 플랫폼 서비스만을 제공할 뿐, "재화"를 판매하는 당사자가 아니며, "재화"에 대한 정보 및 배송, 하자 등에 대한 책임은 "제휴판매자" 에게 있습니다.',
        '"회사"는 "제휴판매자"가 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않습니다.',
        '"회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.',
        '"회사"는 "이용자"의 귀책사유로 인한 "서비스"이용의 장애에 대하여는 책임을 지지 않습니다.',
        '"회사"는 "이용자"가 게재한 이용후기 등 정보/자료/사실의 신뢰도, 정확성에 대해서는 책임을 지지 않습니다.',
        '회사"는 제3자가 서비스 내 화면 또는 링크된 웹사이트를 통하여 광고한 제품 또는 서비스의 내용과 품질에 대해서 감시할 의무가 없으며 및 이와 관련한 기타 어떠한 책임도 지지 않습니다.',
        '"회사"는 "이용자"가 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.',
        '"회사"는 "이용자"간 또는 "이용자"와 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.',
        '"회사" 및 "회사"의 임직원 그리고 대리인은 고의 또는 중대한 과실이 없는 한 다음과 같은 사항으로부터 발생하는 손해에 대해 책임을 지지 않습니다.' +
        '\n'+'1). 회원 정보의 허위 또는 부정확성에 기인하는 손해' +  
        '\n'+'2). 서비스에 대한 접속 및 서비스의 이용과정에서 "이용자"의 귀책사유로 발생하는 손해' +
        '\n'+'3). 서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생하는 손해 및 제3자의 불법적인 행위를 방지하거나 예방하는 과정에서 발생하는 손해' +
        '\n'+'4). 제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나 또는 전송, 유포되도록 한 모든 바이러스, 스파이웨어 및 기타 악성 프로그램으로 인한 손해',

        ],
        OPEN : false
    },
    {
        INDEX :'26.',
        LABEL : "제26조 (분쟁해결)",
        CONTENT :[
        '플랫폼은 이용자를 위하여 이용자와 제휴판매자 사이에서 중재역할을 합니다. 또한, 플랫폼은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 처리하기 위한 인력 및 설비를 갖추고, 운영합니다. 플랫폼은 이용자로부터 제출되는 의견 및 불만사항의 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 통보해야 합니다',
        ],
        OPEN : false
    },
    {
        INDEX :'27.',
        LABEL : "제27조 (재판 및 준거법)",
        CONTENT :[
        '"회사"와 "이용자"간에 발생한 분쟁에 관한 소송은 "회사"의 주소지를 관할하는 법원을 관할법원으로 정합니다',
        '"플랫폼"과 "이용자"간에 제기된 전자상거래 소송에는 한국법을 적용합니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'28.',
        LABEL : "부칙 (시행일)",
        CONTENT :[
        '본 약관은 2024년 8월 5일부터 적용합니다.',

        ],
        OPEN : false
    },
]
export const GPSLAW = [
    {
        INDEX :'01.',
        LABEL : "제1조 (목적)",
        CONTENT :[
        '본 약관은 씨크로드(이하 “회사"라 한다)가 제공하는 위치기반서비스(이하 “서비스“라 한다)를 이용함에 있어 “회사”와 “서비스”를 이용하고자 하는 자(이하 “이용자"라 한다)의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.',

        ],
        OPEN : false
    },
    {
        INDEX :'02.',
        LABEL : "제2조 (이용약관의 효력 및 변경)*",
        CONTENT :[
        '본 약관은 이용자가 본 약관에 동의하고 회사가 정한 소정의 절차에 따라 서비스의 이용자로 인정함으로써 효력이 발생합니다.',
        '이용자가 서비스를 이용하는 과정에서 약관 동의에 체크한 경우, 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 간주합니다.',
        '회사는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.',
        '회사가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자 10일 전부터 적용일 이후 상당한 기간 동안 공지만을 하고, 개정 내용이 이용자에게 불리한 경우에는 그 적용일자 30일 전부터 적용일 이후 상당한 기간 동안 각각 이를 서비스 홈페이지에 게시하거나 이용자에게 전자적 형태(E-mail, SMS, App 메시지 등)로 약관 개정 사실을 발송하여 고지합니다.',
        '회사가 전항에 따라 이용자에게 통지하면서 공지 또는 공지∙고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 이용약관에 승인한 것으로 봅니다. 이용자가 개정약관에 동의하지 않을 경우 이용자는 이용계약을 해지할 수 있습니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'03.',
        LABEL : "제3조 (관계법령의 적용)",
        CONTENT :[
        '본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따릅니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'04.',
        LABEL : "제4조 (서비스의 내용)",
        CONTENT :[
        '회사가 제공하는 서비스, 개인위치정보의 보유목적 및 기간은 아래와 같습니다.',
        '서비스, 개인위치정보의 보유목적 ' +
        '\n'+'-위치정보수집 대상의 실시간 위치확인'+
        '\n'+'-이용자의 위치에서 근접한 위치정보 제공',
        '보유기간' +
        '\n'+'-일회성 또는 임시로 이용 후 지체없이 파기',

        ],
        OPEN : false
    },
    {
        INDEX :'05.',
        LABEL : "제5조 (서비스 이용요금)*",
        CONTENT :[
        '회사가 제공하는 서비스는 기본적으로 무료입니다. 단, 별도의 유료 서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 사용 가능합니다.',
        '회사는 유료 서비스 이용요금을 회사와 계약한 전자지불업체에서 정한 방법에 의하거나 회사가 정한 청구서에 합산하여 청구할 수 있습니다.',
        '유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은 회사의 결제 이용약관 등 관계법에 따릅니다.',
        '이용자의 개인정보도용 및 결제사기로 인한 환불요청 또는 결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될 수 있습니다.',
        '무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각 이동통신사의 정책에 따릅니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'06.',
        LABEL : "제6조 (서비스 내용변경 통지 등)*",
        CONTENT :[
        '회사가 서비스 내용을 변경하거나 종료하는 경우, 회사는 이용자가 회원가입 시 제공한 E-mail, SMS 또는 App 메시지 등을 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있습니다.',
        '①항의 경우 불특정 다수인을 상대로 통지를 함에 있어서는 웹사이트 등 기타 회사의 공지사항을 통하여 이용자들에게 통지할 수 있습니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'07.',
        LABEL : "제7조 (서비스 이용의 제한 및 중지)*",
        CONTENT :[
        '회사는 아래 각 호의 1에 해당하는 사유가 발생한 경우에는 이용자의 서비스 이용을 제한하거나 중지시킬 수 있습니다.' +
        '\n'+'1). 이용자가 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우'+
        '\n'+'2). 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우' +    
        '\n'+'3). 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우' + 
        '\n'+'4). 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때' +
        '\n'+'5). 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우'   ,
        '회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 이용자에게 알려야 합니다.'
        ],
        OPEN : false
    },
    {
        INDEX :'08.',
        LABEL : "제8조 (개인위치정보의 이용 또는 제공)*",
        CONTENT :[
        '회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에 명시한 후 개인위치정보주체의 동의를 얻어야 합니다.',
        '이용자 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의하며, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.',
        '회사는 타사업자 또는 이용자와의 요금정산 및 민원처리를 위해 위치정보 이용•제공․사실 확인자료를 자동 기록•보존하며, 해당 자료는 1년간 보관합니다.',
        '회사는 개인위치정보를 이용자가 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 매회 이용자에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다. 단, 아래 각 호의 1에 해당하는 경우에는 이용자가 미리 특정하여 지정한 통신 단말장치 또는 전자우편주소로 통보합니다.' + 
        '\n'+'1). 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우' +
        '\n'+'2). 이용자가 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우'
        ],
        OPEN : false
    },
    {
        INDEX :'09.',
        LABEL : "제9조 (개인위치정보주체의 권리)*",
        CONTENT :[
        '이용자는 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.',
        '이용자는 회사에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.',
        '이용자는 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 회원의 요구를 거절할 수 없습니다.' +
        '\n'+'1. 본인에 대한 위치정보 수집, 이용, 제공사실 확인자료' +
        '\n'+'2. 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용',
        '이용자는 제1항 내지 제3항의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.',
  
        ],
        OPEN : false
    },
    {
        INDEX :'10.',
        LABEL : "제10조 (법정대리인의 권리)*",
        CONTENT :[
        '만 14세 미만 이용자로서 개인위치정보를 활용한 위치기반 서비스를 이용하는 경우 회사는 이용자의 개인위치정보를 이용 및 제3자에게 제공하게 되며, 법정대리인의 동의를 받아야 합니다.(서면, 고객행복센터 등을 통해)',
        '1항의 경우 법정대리인은 제9조에 의한 이용자의 권리를 모두 가집니다.',
        '만 14세 미만 이용자가 법정대리인의 동의 없이 위치기반 서비스를 이용한 것으로 판명된 때에는 회사는 즉시 해당 개인위치정보를 삭제하거나, 위치기반 서비스 이용을 중단하는 등 적절한 제한을 할 수 있습니다.',

        ],
        OPEN : false
    },
    {
        INDEX :'11.',
        LABEL : "제11조 (위치정보관리책임자의 지정)",
        CONTENT :[
        '회사는 위치정보를 적절히 관리․보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다',
        '위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'12.',
        LABEL : "제12조 (손해배상)*",
        CONTENT :[
        '회사가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 이용자에게 손해가 발생한 경우 이용자는 회사에 대하여 손해배상 청구를 할 수 있습니다. 이 경우 회사는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.이용자가 서비스 및 혜택받기를 이용하면서 불법행위 또는 고의나 과실로 이 약관을 위반하여 회사가 해당 고객 이외의 제 3자로부터 손해배상청구 또는 소송을 비롯한 각종 이의제기를 받는다면 해당 이용자는 그 때문에 회사에 발생한 손해를 배상해야 합니다.                          ',
        '이용자가 본 약관의 규정을 위반하여 회사에 손해가 발생한 경우 회사는 이용자에 대하여 손해배상을 청구할 수 있습니다. 이 경우 이용자는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'13.',
        LABEL : "제13조 (면책)",
        CONTENT :[
        '회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 이용자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.' +
        '\n'+'1). 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우'+
        '\n'+'2). 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우'+
        '\n'+'3). 이용자의 귀책사유로 서비스 이용에 장애가 있는 경우'+
        '\n'+'4). 제1호 내지 제3호를 제외한 기타 회사의 고의∙과실이 없는 사유로 인한 경우',
        '회사는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 이용자의 손해에 대하여는 책임을 부담하지 아니합니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'14.',
        LABEL : "제14조 (규정의 준용)",
        CONTENT :[
        '본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.',
        '본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에 의합니다',
        ],
        OPEN : false
    },
    {
        INDEX :'15.',
        LABEL : "제15조 (분쟁의 조정 및 기타)*",
        CONTENT :[
        '회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.',
        '회사 또는 이용자는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보보호법 제43조의 규정에 의한 개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.',
        ],
        OPEN : false
    },
    {
        INDEX :'16.',
        LABEL : "제16조 (회사의 연락처)*",
        CONTENT :[
        '회사의 상호 및 주소등은 다음과 같습니다.' +
        '\n'+'상 호 : ㈜씨크로드' +
        '\n'+'대 표 자 : 마원' +
        '\n'+'주 소 : 마원주소' +
        '\n'+'대표전화 : 1800-9000',
        ],
        OPEN : false
    },
    {
        INDEX :'17.',
        LABEL : "부칙 (시행일)*",
        CONTENT :[
        '이 약관은 2022년 11월 14일부터 시행합니다' +
        '\n'+'위치정보관리책임자는 2022년 11월을 기준으로 다음과 같이 지정합니다.' +
        '\n'+'소 속 : ㈜씨크로드' +
        '\n'+'성 명 : 마원' +
        '\n'+'연락처 : 1800-9000',
   
        ],
        OPEN : false
    },

]