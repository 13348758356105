export const LIFEMENU={
    TOUR : '국내관광',
    PERFORMANCE : "영화공연",
    FOOD :"식품정보",
    CONVENIENCE : "생활편의",
    MEDICAL : "의료정보",
    WEATHER : "날씨환경",
    BOARD :"게시판",
}

export const TOURISTMENU ={
    TOURREGION : '전국관광지',
    TOURFESTIVAL : '전국문화축제',
    TOURCOUNTRY : '향토문화유적',
    TOURPICTURE: '관광사진',
}
export const PERFORMANCEMENU ={
    PERFORMANCEEVENT : '공연행사/할인',
    PERFORMANCECINEMA : '공공시설개방',

}

export const FAMILYMENU ={
    FAMILYABANDON : '유기동물 정보',
    FAMILYLOST : '분실물 정보',
}
export const CONVENIENCEMENU ={
    CONVENIENCECAMPING : '전국캠핑장',
}
export const BOARDMENU ={
    BOARD : '게시판',
}

export const MEDICALMENU ={
    MEDICALMEDICINE : '의약품개요',
    MEDICALHOSPITAL : '의료기관',
    FOODINFOMATION: '건강기능식품',

}

