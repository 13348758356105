import { ROOMSIZE } from "./room";
import { WORKNAME } from "./work_";

export const imageDB = {
  download: require("../assets/imageset/download.png"),
  pclogo: require("../assets/imageset/pclogo.png"),
  logogif: require("../assets/imageset/logogif.gif"),
  init : require("../assets/imageset/init.png"),
  enablecheck: require("../assets/imageset/enablecheck.png"),
  disablecheck: require("../assets/imageset/disablecheck.png"),
  search: require("../assets/imageset/search.png"),
  infocircle: require("../assets/imageset/info-circle.png"),
  redsearch: require("../assets/imageset/redsearch.png"),
  logout: require("../assets/imageset/logout.png"),
  user: require("../assets/imageset/user.png"),
  pcslpash: require("../assets/imageset/PCSplash.png"),
  mobileslpash: require("../assets/imageset/MobileSplash.png"),
  Empty : require("../assets/imageset/Empty.png"),
  tour: require("../assets/imageset/tour.png"),
  tourcountry: require("../assets/imageset/tourcountry.png"),
  medical: require("../assets/imageset/medical.png"),
  food: require("../assets/imageset/food.png"),
  convenience: require("../assets/imageset/convenience.png"),
  board: require("../assets/imageset/board.png"),
  performance: require("../assets/imageset/performance.png"),
  expand: require("../assets/imageset/expand.png"),
  success: require("../assets/imageset/success.png"),
  warning: require("../assets/imageset/warning.png"),
  fail: require("../assets/imageset/fail.png"),
  dot : require("../assets/imageset/dot.png"),
  heartoff: require("../assets/imageset/heartoff.png"),

  person : require("../assets/imageset/person.png"),
  logo: require("../assets/imageset/logo.png"),
  logo2: require("../assets/imageset/logo2.png"),
  honglogo : require("../assets/imageset/honglogo.png"),
  uploadenable : require("../assets/imageset/uploadenable.png"),
  uploaddisable : require("../assets/imageset/uploaddisable.png"),
  mobilebanner1 :require("../assets/imageset/mobilebanner1.png"),
  mobilebanner2 :require("../assets/imageset/mobilebanner2.png"),
  mobilebanner3 :require("../assets/imageset/mobilebanner3.png"),
  mobilebanner4 :require("../assets/imageset/mobilebanner4.png"),
  mobilebanner5 :require("../assets/imageset/mobilebanner5.png"),
  mobilebanner6 :require("../assets/imageset/mobilebanner6.png"),
  mobilebanner7 :require("../assets/imageset/mobilebanner7.png"),
  mobilebanner8 :require("../assets/imageset/mobilebanner8.png"),
  mobilebanner9 :require("../assets/imageset/mobilebanner9.png"),
  mobilebanner10 :require("../assets/imageset/mobilebanner10.png"),
  mobilebanner11 :require("../assets/imageset/mobilebanner11.png"),
  roomplus :require("../assets/imageset/roomplus.png"),
  movegps :require("../assets/imageset/movegps.png"),
  movegpsex :require("../assets/imageset/movegps.gif"),
  check_d:require("../assets/imageset/check_d.png"),
  check_e:require("../assets/imageset/check_e.png"),
  enablecheck:require("../assets/imageset/enablecheck.png"),
  filter:require("../assets/imageset/filter.png"),
  filterblack:require("../assets/imageset/filterblack.png"),
  reset:require("../assets/imageset/reset.png"),
  memo:require("../assets/imageset/memo.png"),
  enablememo:require("../assets/imageset/enablememo.png"),
  Up:require("../assets/imageset/Up.png"),
  drug :require("../assets/imageset/drug.png"),
  healthfood :require("../assets/imageset/healthfood.png"),
  camping :require("../assets/imageset/camping.png"),
  loading :require("../assets/imageset/loading.json"),
  loadinglarge :require("../assets/imageset/loadinglarge.json"),
  pinmove :require("../assets/imageset/pin_h.json"),
  gps :require("../assets/imageset/movegps.png"),
  selection_d :require("../assets/imageset/selection_d.png"),
  selection_e :require("../assets/imageset/selection_e.png"),
  roomsample1: require("../assets/imageset/roomsample1.png"),
  roomsample2: require("../assets/imageset/roomsample2.png"),
  roomsample3: require("../assets/imageset/roomsample3.png"),
  roomsample4: require("../assets/imageset/roomsample4.png"),
  roomsample5: require("../assets/imageset/roomsample5.png"),
  roomsample6: require("../assets/imageset/roomsample6.png"),
  roomsample7: require("../assets/imageset/roomsample7.png"),
  roomsample8: require("../assets/imageset/roomsample8.png"),

  roomsize1: require("../assets/imageset/roomsize1.png"),
  roomsize2: require("../assets/imageset/roomsize2.png"),
  roomsize3: require("../assets/imageset/roomsize3.png"),
  roomsize4: require("../assets/imageset/roomsize4.png"),
  roomsize5: require("../assets/imageset/roomsize5.png"),
  roomsize6: require("../assets/imageset/roomsize6.png"),



  sample1: require("../assets/imageset/sample1.png"),
  sample2: require("../assets/imageset/sample2.png"),
  sample3: require("../assets/imageset/sample3.png"),
  sample4: require("../assets/imageset/sample4.png"),
  sample5: require("../assets/imageset/sample5.png"),
  sample6: require("../assets/imageset/sample6.png"),
  sample7: require("../assets/imageset/sample7.png"),
  sample8: require("../assets/imageset/sample8.png"),
  sample9: require("../assets/imageset/sample9.png"),
  sample10: require("../assets/imageset/sample10.png"),
  sample11: require("../assets/imageset/sample11.gif"),
  sample12: require("../assets/imageset/sample12.gif"),
  sample13: require("../assets/imageset/sample13.gif"),
  sample14: require("../assets/imageset/sample14.gif"),
  sample14_new: require("../assets/imageset/sample14_new.gif"),
  sample15: require("../assets/imageset/sample15.png"),
  sample16: require("../assets/imageset/sample16.png"),
  sample17: require("../assets/imageset/sample17.png"),
  sample18: require("../assets/imageset/sample18.png"),
  sample19: require("../assets/imageset/sample19.gif"),
  sample20: require("../assets/imageset/sample20.gif"),
  sample21: require("../assets/imageset/sample21.gif"),
  sample22: require("../assets/imageset/sample22.gif"),
  sample23: require("../assets/imageset/sample23.gif"),
  sample24: require("../assets/imageset/sample24.gif"),
  sample25: require("../assets/imageset/sample25.png"),
  sample26: require("../assets/imageset/sample26.png"),
  sample27: require("../assets/imageset/sample27.png"),
  sample28: require("../assets/imageset/sample23.gif"),
  sample29: require("../assets/imageset/sample29.png"),
  sample30: require("../assets/imageset/sample30.gif"),
  sample31: require("../assets/imageset/sample31.gif"),
  sample32: require("../assets/imageset/sample32.gif"),
  sample33: require("../assets/imageset/sample33.png"),
  sample34: require("../assets/imageset/logotext.png"),
  sample35: require("../assets/imageset/sample35.png"),
  sample36: require("../assets/imageset/sample36.png"),
  sample37: require("../assets/imageset/sample37.png"),
  searchgif : require("../assets/imageset/search.gif"),
  sample38: require("../assets/imageset/sample38.png"),
  sample39: require("../assets/imageset/sample39.png"),
  license: require("../assets/imageset/license.png"),

  communitylist1: require("../assets/imageset/communitylist1.png"),
  communitylist2: require("../assets/imageset/communitylist2.png"),
  communitylist3: require("../assets/imageset/communitylist3.png"),
  communitylist4: require("../assets/imageset/communitylist4.png"),
  communitylist5: require("../assets/imageset/communitylist5.png"),
  communitylist6: require("../assets/imageset/communitylist6.png"),
  communitylist7: require("../assets/imageset/communitylist7.png"),
  communitylist8: require("../assets/imageset/communitylist8.png"),
  communitylist9: require("../assets/imageset/communitylist9.png"),
  communitylist10: require("../assets/imageset/communitylist10.png"),
  communitylist11: require("../assets/imageset/communitylist11.png"),
  communitylist12: require("../assets/imageset/communitylist12.png"),
  communitylist13: require("../assets/imageset/communitylist13.png"),


  close: require("../assets/imageset/close.png"),
  close2: require("../assets/imageset/close2.png"),
  bell: require("../assets/imageset/bell.png"),
  hongmoveicon: require("../assets/imageset/hongmove.png"),
  hongmainicon: require("../assets/imageset/hongmain.png"),
  house: require("../assets/imageset/house.png"),
  housesmall: require("../assets/imageset/housesmall.png"),
  housegray: require("../assets/imageset/housegray.png"),
  housegraysmall: require("../assets/imageset/housegraysmall.png"),
  business: require("../assets/imageset/business.png"),
  businesssmall: require("../assets/imageset/businesssmall.png"),
  businessgray: require("../assets/imageset/businessgray.png"),
  businessgraysmall: require("../assets/imageset/businessgraysmall.png"),
  move: require("../assets/imageset/move.png"),
  movesmall: require("../assets/imageset/movesmall.png"),
  movegray: require("../assets/imageset/movegray.png"),
  movegraysmall: require("../assets/imageset/movegraysmall.png"),
  cook: require("../assets/imageset/cook.png"),
  cooksmall: require("../assets/imageset/cooksmall.png"),
  cookgray: require("../assets/imageset/cookgray.png"),
  cookgraysmall: require("../assets/imageset/cookgraysmall.png"),
  help:require("../assets/imageset/help.png"),
  helpsmall:require("../assets/imageset/helpsmall.png"),
  helpgray:require("../assets/imageset/helpgray.png"),
  helpgraysmall:require("../assets/imageset/helpgraysmall.png"),
  gooutschool: require("../assets/imageset/gooutschool.png"),
  gooutschoolsmall: require("../assets/imageset/gooutschoolsmall.png"),
  gooutschoolgray: require("../assets/imageset/gooutschoolgray.png"),
  gooutschoolgraysmall: require("../assets/imageset/gooutschoolgraysmall.png"),
  hospital: require("../assets/imageset/hospital.png"),
  hospitalsmall: require("../assets/imageset/hospitalsmall.png"),
  hospitalgray: require("../assets/imageset/hospitalgray.png"),
  hospitalgraysmall: require("../assets/imageset/hospitalgraysmall.png"),
  babycare: require("../assets/imageset/babycare.png"),
  babycaresmall: require("../assets/imageset/babycaresmall.png"),
  babycaregray: require("../assets/imageset/babycaregray.png"),
  babycaregraysmall: require("../assets/imageset/babycaregraysmall.png"),
  carry: require("../assets/imageset/carry.png"),
  carrysmall: require("../assets/imageset/carrysmall.png"),
  carrygray: require("../assets/imageset/carrygray.png"),
  carrygraysmall: require("../assets/imageset/carrygraysmall.png"),
  patientcare : require("../assets/imageset/patientcare.png"),
  patientcaresmall : require("../assets/imageset/patientcaresmall.png"),
  patientcaregray : require("../assets/imageset/patientcaregray.png"),
  patientcaregraysmall : require("../assets/imageset/patientcaregraysmall.png"),
  recipe: require("../assets/imageset/recipe.png"),
  recipesmall: require("../assets/imageset/recipesmall.png"),
  recipegray: require("../assets/imageset/recipegray.png"),
  recipegraysmall: require("../assets/imageset/recipegraysmall.png"),
  schoolevent : require("../assets/imageset/schoolevent.png"),
  schooleventsmall : require("../assets/imageset/schooleventsmall.png"),
  schooleventgray : require("../assets/imageset/schooleventgray.png"),
  schooleventgraysmall : require("../assets/imageset/schooleventgraysmall.png"),
  dog : require("../assets/imageset/dog.png"),
  dogsmall : require("../assets/imageset/dogsmall.png"),
  doggray : require("../assets/imageset/doggray.png"),
  doggraysmall : require("../assets/imageset/doggraysmall.png"),
  doghospital : require("../assets/imageset/doghospital.png"),
  doghospitalsmall : require("../assets/imageset/doghospitalsmall.png"),
  doghospitalgray : require("../assets/imageset/doghospitalgray.png"),
  doghospitalgraysmall : require("../assets/imageset/doghospitalgraysmall.png"),
  shopping : require("../assets/imageset/shopping.png"),
  shoppingsmall : require("../assets/imageset/shoppingsmall.png"),
  shoppinggray : require("../assets/imageset/shoppinggray.png"),
  shoppinggraysmall : require("../assets/imageset/shoppinggraysmall.png"),
  lesson : require("../assets/imageset/lesson.png"),
  lessonsmall : require("../assets/imageset/lessonsmall.png"),
  lessongray : require("../assets/imageset/lessongray.png"),
  lessongraysmall : require("../assets/imageset/lessongraysmall.png"),
  map8: require("../assets/imageset/map8.png"),
  map9: require("../assets/imageset/map9.png"),
  eyesolid : require("../assets/imageset/eye-solid.png"),
  rulletstart : require("../assets/imageset/rulletstart.png"),
  rulletpin : require("../assets/imageset/rulletpin.png"),
  rulletsuccess : require("../assets/imageset/rulletsuccess.gif"),
  map : require("../assets/imageset/map.png"),
  mappin : require("../assets/imageset/map-pin.png"),
  distance : require("../assets/imageset/distance.png"),

  home_e :require("../assets/imageset/home_e.png"),
  home_d :require("../assets/imageset/home_d.png"),
  room_e :require("../assets/imageset/room_e.png"),
  room_d :require("../assets/imageset/room_d.png"),
  community_e :require("../assets/imageset/community_e.png"),
  community_d :require("../assets/imageset/community_d.png"),
  myinfo_e :require("../assets/imageset/myinfo_e.png"),
  myinfo_d :require("../assets/imageset/myinfo_d.png"),

};



export const Seekimage = (category) =>{
  if(category == WORKNAME.ALLWORK){
    return imageDB.pclogo;
  }
  if(category == WORKNAME.HOMECLEAN){
    return imageDB.house;
  }else if(category == WORKNAME.BUSINESSCLEAN){
    return imageDB.business;
  }else if(category == WORKNAME.MOVECLEAN){
    return imageDB.move;
  }else if(category == WORKNAME.FOODPREPARE){
    return imageDB.cook;
  }else if(category == WORKNAME.GOOUTSCHOOL){
    return imageDB.gooutschool;
  }else if(category == WORKNAME.BABYCARE){
    return imageDB.babycare;
  }else if(category == WORKNAME.ERRAND){
    return imageDB.help;
  }else if(category == WORKNAME.PATIENTCARE){
    return imageDB.patientcare;
  }else if(category == WORKNAME.CARRYLOAD){
    return imageDB.carry;
  }else if(category == WORKNAME.GOHOSPITAL){
    return imageDB.hospital;
  }else if(category == WORKNAME.RECIPETRANSMIT){
    return imageDB.recipe;
  }else if(category == WORKNAME.GOSCHOOLEVENT){
    return imageDB.schoolevent;
  }else if(category == WORKNAME.SHOPPING){
    return imageDB.shopping;
  }else if(category == WORKNAME.GODOGHOSPITAL){
    return imageDB.doghospital;
  }else if(category == WORKNAME.GODOGWALK){
    return imageDB.dog;
  }else if(category == WORKNAME.LESSON){
    return imageDB.lesson;
  }else if(category == ROOMSIZE.SMALLER){
    return imageDB.roomsize1;
  }else if(category == ROOMSIZE.SMALL){
    return imageDB.roomsize2;
  }else if(category == ROOMSIZE.MEDIUM){
    return imageDB.roomsize3;
  }else if(category == ROOMSIZE.LARGE){
    return imageDB.roomsize4;
  }else if(category == ROOMSIZE.EXLARGE){
    return imageDB.roomsize5;
  }
}
export const Seekgrayimage = (category) =>{
  if(category == WORKNAME.ALLWORK){
    return imageDB.pclogo;
  }
  if(category == WORKNAME.HOMECLEAN){
    return imageDB.housegray;
  }else if(category == WORKNAME.BUSINESSCLEAN){
    return imageDB.businessgray;
  }else if(category == WORKNAME.MOVECLEAN){
    return imageDB.movegray;
  }else if(category == WORKNAME.FOODPREPARE){
    return imageDB.cookgray;
  }else if(category == WORKNAME.GOOUTSCHOOL){
    return imageDB.gooutschoolgray;
  }else if(category == WORKNAME.BABYCARE){
    return imageDB.babycaregray;
  }else if(category == WORKNAME.ERRAND){
    return imageDB.helpgray;
  }else if(category == WORKNAME.PATIENTCARE){
    return imageDB.patientcaregray;
  }else if(category == WORKNAME.CARRYLOAD){
    return imageDB.carrygray;
  }else if(category == WORKNAME.GOHOSPITAL){
    return imageDB.hospitalgray;
  }else if(category == WORKNAME.RECIPETRANSMIT){
    return imageDB.recipegray;
  }else if(category == WORKNAME.GOSCHOOLEVENT){
    return imageDB.schooleventgray;
  }else if(category == WORKNAME.SHOPPING){
    return imageDB.shoppinggray;
  }else if(category == WORKNAME.GODOGHOSPITAL){
    return imageDB.doghospitalgray;
  }else if(category == WORKNAME.GODOGWALK){
    return imageDB.doggray;
  }else if(category == WORKNAME.LESSON){
    return imageDB.lessongray;
  }else if(category == ROOMSIZE.SMALLER){
    return imageDB.roomsize1;
  }else if(category == ROOMSIZE.SMALL){
    return imageDB.roomsize2;
  }else if(category == ROOMSIZE.MEDIUM){
    return imageDB.roomsize3;
  }else if(category == ROOMSIZE.LARGE){
    return imageDB.roomsize4;
  }else if(category == ROOMSIZE.LARGER){
    return imageDB.roomsize5;
  }else if(category == ROOMSIZE.EXLARGE){
    return imageDB.roomsize6;
  }
}


