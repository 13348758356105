/**
 * TODO : USER_ID 임시적으로 사용
 */
export const USER_TMP_ID = "94570matrix~"

export const USERTABLE = {
	USER_ID :"USER_ID",
	NAME :"NAME",
    TEL : "TEL",
    ADDR1 : "ADDR1",
    ADDR2 : "ADDR2",
    LONGITUDE :"LONGITUDE",
    LATITUDE : "LATITUDE",
    JUMIN : "JUMIN",
    USERSTATUS :"USERSTATUS",
    INTRODUCE:"INTRODUCE",
    CHARACTERS :"CHARACTERS",
    CREATEDT:"CREATEDT",	
}

export const WORK ={
    WORK_ID:"WORK_ID",
    USER_ID:"USER_ID",
    WORK_INFO:"WORK_INFO",
    WORKSTATUS:"WORKSTATUS",
    CREATEDT:"CREATEDT",
}

export const HELP={
    HELP_ID :"HELP_ID",
    WORK_ID:"WORK_ID",
    USER_ID:"USER_ID",
    HELP_COMMENT:"HELPCOMMENT",
    HELPSTATUS:"HELPSTATUS",
    CREATEDT:"CREATEDT"
}

export const WORKREVIEW={
    WORKREVIEW_ID:"WORKREVIEW_ID",
    WORK_ID:"WORK_ID",
    USER_ID:"USER_ID",
    GRADE:"GRADE",
    CONTENT:"CONTENT",
    CHARACTERS:"CHARACTERS",
    CREATEDT:"CREATEDT"
}

export const COMMUNTIYREVIEW={
    COMMUNTIYREVIEW_ID:"COMMUNTIYREVIEW_ID",
    COMMUNITY_ID:"COMMUNITY_ID",
    CONTENT:"CONTENT",
    CREATEDT:"CREATEDT"
}

export const PRIZE ={
    PRIZE_ID:"PRIZE_ID",
    USER_ID:"USER_ID",
    PRIZETYPE:"PRIZETYPE",
    USERSTATUS:"USERSTATUS",
    CREATEDT:"CREATEDT"
}

export const COUPONE ={
    COUPONE_ID:"COUPONE_ID",
    USER_ID:"USER_ID",
    COUPONETYPE:"COUPONETYPE",
    POINT:"POINT",
    CONTENT:"CONTENT",
    COUPONESTATUS:"COUPONESTATUS",
    CREATEDT:"CREATEDT"
}

export const ATTEND ={
    ATTEND_ID:"ATTEND_ID",
    USER_ID:"USER_ID",
    CREATEDT:"CREATEDT"
}

export const POINT = {
    POINT_ID:"POINT_ID",
    USER_ID:"USER_ID",
    POINTSTATUS:"POINTSTATUS",
    POINT:"POINT",
    POINTDIRECTION:"POINTDIRECTION",
    CREATEDT:"CREATEDT"
}

export const CHANNEL={
    CHANNEL_ID:"CHANNEL_ID",
    WORK_ID:"WORK_ID",
    MAKER_ID:"MAKER_ID",
    HELPER_ID :"HELPER_ID",
    CHANNELSTATUS:"CHANNELSTATUS",
    CREATEDT:"CREATEDT",
    CONTACT_ID:"CONTACT_ID",
    WORK_INFO:"WORK_INFO",
    RESULT:"RESULT",
    FEE:"FEE",
    MESSAGES:"MESSAGES"
}

export const MESSAGES={
    MESSAGES_ID : "MESSAGES_ID",
    IMGTYPE:"IMGTYPE",
    CREATEDT:"CREATEDT",
    READUSERS:"READUSERS",
    TEXT:"TEXT",
    ALLUSERS:"ALLUSERS",
    
}

export const COMMUNITY={
    COMMUNITY_ID : "COMMUNITY_ID",
    CREATEDT:"CREATEDT",
    COMMUNITYCATEGORY:"COMMUNITYCATEGORY",
    LABEL :"LABEL",
    USER_ID: "USER_ID",
    CONTENT:"CONTENT",
    REPRESENTIMG :"REPRESENTIMG",
    COMMNUNITYSTATUS :"COMMNUNITYSTATUS",
    VIEWUSERS:"VIEWUSERS",
    HEARTUSERS:"HEARTUSERS",
    COMMUNTIYREVIEWIDS :"COMMNUNITYREVIEWIDS"
    
}


