export const USERSTATUS = {
    ONLY : 0,
    HONGINCLUDE : 1,
}

export const WORKSTATUS ={
	OPEN : 0,
	CLOSE : 1,
}
export const ROOMSTATUS ={
	OPEN : 0,
	CLOSE :1,
}


export const HELPSTATUS ={
	REQUEST : 0,
	ACCEPT : 1,
}

export const CHANNELSTATUS ={
	INIT : 0,
	CHECK : 1,
	CONTACT : 2,
	APPROVAL : 3,
	COMPLETE : 4,
}

export const PRIZESTATUS ={
	USEENABLE :0,
	USED :1, 
}

export const COUPONESTATUS = {
	USEENABLE :0,
	USED :1, 	
}

export const POINTDIRECTION ={
	ADD : 0,
	DECREASE :1,
}

export const APPROVALSTATUS = {
	ESCRO : 0,
	COMPLETE :1
}

export const COMMUNITYSTATUS = {
	REGIST : 0,
	TMPREGIST : 1,
	REPORT :2,
}